const address = apiUrls.dealshakerAdmin;
const messagingCenterAddress = apiUrls.messaging;

export default {
  ADDRESS: address,
  USER_ME: `${address}/api/Users/Me`,
  NOTIFICATIONS: `${address}/api/messaging-center/Notifications/AllNew`,
  NOTIFICATIONS_MARK_SEEN: `${address}/api/messaging-center/Notifications/MarkAllAsSeen`,
  MEDIA_ADD_IMAGE: `${address}/api/Media/AddImage`,
  MEDIA_REMOVE: (id) => `${address}/api/Media/DeleteDealMedia/${id}`,
  CHAT_LIST_CHATS: `${messagingCenterAddress}/api/messaging-center/Chat/ListChats`,
  CHAT_LIST_GROUP_CHATS: `${messagingCenterAddress}/api/messaging-center/Chat/ListGroupChats`,
  CHAT_LIST_MODERATOR_CHATS: `${messagingCenterAddress}/api/messaging-center/Chat/ListModeratorChats`,
  CHAT_GET_ALL_UNREAD: `${messagingCenterAddress}/api/messaging-center/Chat/AllUnread`,
  CHAT_GET: (chatId) => `${messagingCenterAddress}/api/messaging-center/Chat/Get/${chatId}`,
  CHAT_GET_METADATA: (chatId) => `${messagingCenterAddress}/api/messaging-center/Chat/GetMetadata/${chatId}`,
  CHAT_GET_METADATA_FOR_USER_AND_DEAL: `${messagingCenterAddress}/api/messaging-center/Chat/GetMetadata`,
  CHAT_GET_MODERATOR: `${messagingCenterAddress}/api/messaging-center/Chat/GetModerator`,
  CHAT_MARK_AS_ARCHIVED: (chatId) => `${messagingCenterAddress}/api/messaging-center/Chat/MarkAsArchived/${chatId}`,
  CHAT_START: `${messagingCenterAddress}/api/messaging-center/Chat/StartChat`,
  CHAT_START_WITH_MESSAGE: `${messagingCenterAddress}/api/messaging-center/Chat/StartChatWithMessage`,
  CHAT_RENAME: `${messagingCenterAddress}/api/messaging-center/Chat/Rename`,
  CHAT_SEND_MESSAGE: `${messagingCenterAddress}/api/messaging-center/Chat/SendMessage`,
  CHAT_SEARCH_USERS: (usernameText) =>
    `${messagingCenterAddress}/api/messaging-center/Chat/SearchUsers?usernameText=${usernameText}`,
  CHAT_LEAVE: (chatId) => `${messagingCenterAddress}/api/messaging-center/Chat/LeaveChat/${chatId}`,
  VERSION_GET_CURRENT: `${address}/api/Version/GetCurrentVersion`,
};
