import { getText } from '@veraio/strank';
import moment from 'moment';
import parseHtml from 'html-react-parser';
import { Tag } from 'components/UIExternal';
import { businessStatusColor, businessStatusInverse } from 'enums';

export const BUSINESS_STATUS_HISTORY_COLUMNS = () => [
  {
    name: getText('changedAt'),
    render: (business) => <span>{moment(business?.createdDate).format('DD/MM/YYYY h:mm:ss')}</span>,
    width: 110,
  },
  {
    name: getText('changedBy'),
    value: 'createdBy',
    width: 180,
  },
  {
    name: getText('reason'),
    dataIndex: 'reason',
    render: (business, NullItem) => (business?.reason ? parseHtml(business.reason) : <NullItem />),
  },
  {
    name: getText('status'),
    render: (business) => (
      <Tag type={businessStatusColor[business?.statusId]}>{getText(businessStatusInverse[business?.statusId])}</Tag>
    ),
    width: 100,
  },
];
